import React from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Typography,
} from "antd";
import {
  getLatestCustomerPortfolio,
  getLogout,
  listCustomer,
  listPortfolio,
  postLogin,
} from "../services/api";
import { Customer, Portfolio } from "../models";
import { DefaultOptionType } from "antd/lib/select";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import {
  allCustomerColumns,
  allPortfolioColumns,
  portfolioColumns,
} from "../constants/columns";
import { calBaht, calPercent } from "../methods";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";

const { Title, Text } = Typography;

interface ModalCustomersProps {
  visible: boolean;
  onModalCustomersClose: () => void;
}

function ModalCustomers(props: ModalCustomersProps) {
  const { visible, onModalCustomersClose } = props;
  const navigate = useNavigate();
  const [customers, setCustomers] = React.useState<Customer[]>([]);

  React.useEffect(() => {
    listCustomer().then((c) => {
      setCustomers(c);
    });
  }, []);

  return (
    <Modal visible={visible} footer={null} onCancel={onModalCustomersClose}>
      <Table
        columns={allCustomerColumns}
        dataSource={customers}
        pagination={false}
        scroll={{ x: true }}
        size="small"
        rowKey={"customer_id"}
        style={{ marginTop: 20 }}
      />
    </Modal>
  );
}

export default ModalCustomers;
