import React from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Typography,
} from "antd";
import {
  getLatestCustomerPortfolio,
  getLogout,
  listCustomer,
  postLogin,
} from "../services/api";
import { Customer, Portfolio } from "../models";
import { DefaultOptionType } from "antd/lib/select";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { portfolioColumns } from "../constants/columns";
import { calBaht, calPercent } from "../methods";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import COLORS from "../constants/color";

const { Title, Text } = Typography;

const grids = {
  xs: 12,
  md: 6,
};

function Summary() {
  const navigate = useNavigate();
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [customersDict, setCustomersDict] = React.useState<{
    [customer_id: string]: Customer;
  }>({});

  React.useEffect(() => {
    listCustomer().then((c) => {
      setCustomers(c);
      setCustomersDict(c.reduce((a, x) => ({ ...a, [x.customer_id]: x }), {}));
    });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: 1,
          minHeight: 0,
          overflow: "auto",
          // padding: 20,
          // backgroundColor: "#e4eaf0",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: COLORS.CARD_BG,
            marginTop: 10,
            padding: 20,
          }}
        >
          <div
            style={{
              textAlign: "center",
              marginBottom: 25,
            }}
          >
            <Title
              level={3}
              style={{
                margin: 0,
              }}
            >
              ภาพรวม
            </Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col {...grids}>
              <Statistic title="จำนวนลูกค้า" value={(customers || []).length} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Summary;
