import { ColumnsType } from "antd/lib/table";
import { Customer, Portfolio } from "../models";
import { Typography } from "antd";
import { calBaht, calPercent } from "../methods";

const { Text } = Typography;

const renderUnrealized = (record: Portfolio) => {
  const percent = calPercent(record);
  const baht = calBaht(record);
  let color = "white";
  if (percent != 0 && percent != null) {
    color = percent > 0 ? "#5ec200" : "#ff653b";
  }
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Text style={{ whiteSpace: "nowrap", color: color }}>
        {`${percent?.format(2) || "N/A"} %`}
      </Text>
      <Text
        style={{
          whiteSpace: "nowrap",
          color: "white",
          opacity: 0.5,
          fontSize: 12,
        }}
      >
        {`(${baht?.format(2) || "N/A"}) ฿`}
      </Text>
    </div>
  );
};

const sorterUnrealized = (a: Portfolio, b: Portfolio) => {
  const percentA = calPercent(a) || 0;
  const percentB = calPercent(b) || 0;
  return percentA - percentB;
};

const renderName = (record: Customer) => {
  return `${record.title ? `${record.title} ` : ""}${
    record.first_name || "(ไม่มีชื่อ)"
  }${record.tel ? ` (${record.tel})` : ""}`;
};

const renderSymbol = (record: Portfolio) => {
  return `${record.symbol.symbol}${record.is_nvdr ? "-NVDR" : ""}${
    record.ty != null ? ` (${record.ty})` : ""
  }`;
};

export const portfolioColumns: ColumnsType<Portfolio> = [
  {
    key: "symbol",
    title: "Symbol",
    fixed: "left",
    render: (value, record) => renderSymbol(record),
  },
  {
    key: "unit_capital_price",
    title: "ราคาต้นทุน",
    dataIndex: "unit_capital_price",
    align: "center",
    render: (value, record) => value?.format(2),
  },
  {
    key: "market_price",
    title: "ราคาตลาด",
    dataIndex: ["symbol", "market_price"],
    align: "center",
    render: (value, record) => value?.format(2),
  },
  {
    key: "quantity",
    title: "จำนวนที่มีอยู่",
    dataIndex: "quantity",
    align: "center",
    render: (value, record) => value?.format(),
  },
  {
    key: "unrealized",
    title: "กำไร / ขาดทุน",
    fixed: "right",
    align: "center",
    sorter: sorterUnrealized,
    render: (value, record) => renderUnrealized(record),
  },
];

export const allPortfolioColumns: ColumnsType<Portfolio> = [
  {
    key: "A/C",
    title: "A/C",
    dataIndex: ["customer", "customer_id"],
    fixed: "left",
  },
  {
    key: "A/O",
    title: "A/O",
    render: (text, record) => record?.customer.marketer_id || "-",
  },
  {
    key: "name",
    title: "Name",
    render: (text, record) => renderName(record?.customer),
  },
  {
    key: "symbol",
    title: "Symbol",
    render: (value, record) => renderSymbol(record),
  },
  {
    key: "unit_capital_price",
    title: "ราคาต้นทุน",
    dataIndex: "unit_capital_price",
    align: "center",
    render: (value, record) => value?.format(2),
  },
  {
    key: "market_price",
    title: "ราคาตลาด",
    dataIndex: ["symbol", "market_price"],
    align: "center",
    render: (value, record) => value?.format(2),
  },
  {
    key: "quantity",
    title: "จำนวนที่มีอยู่",
    dataIndex: "quantity",
    align: "center",
    render: (value, record) => value?.format(),
  },
  {
    key: "unrealized",
    title: "กำไร / ขาดทุน",
    fixed: "right",
    align: "center",
    sorter: sorterUnrealized,
    render: (value, record) => renderUnrealized(record),
  },
];

export const allCustomerColumns: ColumnsType<Customer> = [
  {
    key: "A/C",
    title: "A/C",
    dataIndex: "customer_id",
    fixed: "left",
  },
  {
    key: "name",
    title: "Name",
    render: (text, record) => renderName(record),
  },
  {
    key: "tel",
    title: "โทรศัพท์",
    dataIndex: "tel",
  },
];
