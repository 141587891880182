import React from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Typography,
} from "antd";
import {
  getLatestCustomerPortfolio,
  getLogout,
  listCustomer,
  postLogin,
} from "../services/api";
import { Customer, Portfolio } from "../models";
import { DefaultOptionType } from "antd/lib/select";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { portfolioColumns } from "../constants/columns";
import { calBaht, calPercent } from "../methods";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import COLORS from "../constants/color";

const { Title, Text } = Typography;

const grids = {
  xs: 12,
  md: 6,
};

const getCutomerValue = (customer: Customer | undefined) => {
  if (!customer) return null;
  return `${customer.customer_id} ${customer.first_name} ${customer.tel}`;
};

function ByUser() {
  const navigate = useNavigate();
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [customersDict, setCustomersDict] = React.useState<{
    [customer_id: string]: Customer;
  }>({});
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer>();
  const [currentPortfolios, setCurrentPortfolios] = React.useState<Portfolio[]>(
    []
  );
  const [stats, setStats] = React.useState<{
    percent?: number;
    baht?: number;
    allCount?: number;
    winCount?: number;
    lossCount?: number;
  }>({});

  React.useEffect(() => {
    listCustomer().then((c) => {
      setCustomers(c);
      setCustomersDict(c.reduce((a, x) => ({ ...a, [x.customer_id]: x }), {}));
    });
  }, []);

  React.useEffect(() => {
    if (selectedCustomer) {
      setCurrentPortfolios([]);
      setStats({});
      getLatestCustomerPortfolio(selectedCustomer).then((portfolios) => {
        setCurrentPortfolios(portfolios);
      });
    }
  }, [selectedCustomer]);

  React.useEffect(() => {
    if (currentPortfolios) {
      const allUnrealizeBaht = (currentPortfolios || []).reduce(
        (a, x) => a + (calBaht(x) || 0),
        0
      );
      const allCapitalBaht = (currentPortfolios || []).reduce(
        (a, x) =>
          a +
          ((x.unit_capital_price ? x.quantity * x.unit_capital_price : 0) || 0),
        0
      );
      const allCount = currentPortfolios?.length || 0;
      const winCount = (currentPortfolios || []).reduce(
        (a, x) => a + ((calBaht(x) || 0) >= 0 ? 1 : 0),
        0
      );
      const lossCount = allCount - winCount;
      setStats({
        percent: (allUnrealizeBaht / allCapitalBaht) * 100,
        baht: allUnrealizeBaht,
        allCount: allCount,
        winCount: winCount,
        lossCount: lossCount,
      });
    }
  }, [currentPortfolios]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          backgroundColor: COLORS.COMP_BG,
          padding: 15,
          position: "relative",
        }}
      >
        <Select
          className="round-select"
          showSearch
          allowClear
          size="large"
          style={{
            width: "100%",
            borderRadius: "15px",
          }}
          placeholder="รหัส, ชื่อลูกค้า, เบอร์โทรศัพท์.."
          value={getCutomerValue(selectedCustomer)}
          onChange={(value, option) => {
            setSelectedCustomer(
              customersDict[(option as DefaultOptionType)?.key]
            );
          }}
        >
          {(customers || []).map((customer, index) => {
            return (
              <Select.Option
                key={customer.customer_id}
                value={getCutomerValue(customer)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    // lineHeight: "60px",
                  }}
                >
                  <Text>{`(${customer.customer_id}) ${customer.first_name}`}</Text>
                  <Text type="secondary">{`${
                    customer.tel ? customer.tel : "(ไม่มีเบอร์โทรศัพท์)"
                  }`}</Text>
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </div>
      {selectedCustomer && (
        <div
          style={{
            flex: 1,
            minHeight: 0,
            overflow: "auto",
            // padding: 20,
            // backgroundColor: "#e4eaf0",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: COLORS.CARD_BG,
              marginTop: 10,
              padding: 20,
            }}
          >
            <div
              style={{
                textAlign: "center",
                marginBottom: 25,
              }}
            >
              <Title
                level={3}
                style={{
                  margin: 0,
                }}
              >
                ภาพรวม
              </Title>
            </div>
            <Row gutter={[16, 16]}>
              <Col {...grids}>
                <Statistic
                  title="% กำไร / ขาดทุน"
                  value={0}
                  formatter={(value) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                            color: !stats?.percent
                              ? "white"
                              : (stats?.percent || 0) > 0
                              ? "#5ec200"
                              : "#ff653b",
                          }}
                        >
                          {`${
                            stats.percent != null && !isNaN(stats.percent!)
                              ? stats.percent?.format(2)
                              : "N/A"
                          } %`}
                        </Text>
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                            color: "white",
                            opacity: 0.5,
                            fontSize: 14,
                          }}
                        >
                          {`(${stats.baht?.format(2) || "N/A"}) ฿`}
                        </Text>
                      </div>
                    );
                  }}
                  valueStyle={{
                    color: !stats?.percent
                      ? "white"
                      : (stats?.percent || 0) > 0
                      ? "#5ec200"
                      : "#ff653b",
                  }}
                  prefix={
                    stats.baht ? (
                      stats.baht > 0 ? (
                        <ArrowUpOutlined />
                      ) : (
                        <ArrowDownOutlined />
                      )
                    ) : null
                  }
                />
              </Col>
              <Col {...grids}>
                <Statistic
                  title="จำนวนหุ้นทั้งหมด"
                  value={stats.allCount || 0}
                />
              </Col>
              <Col {...grids}>
                <Statistic
                  title="จำนวนหุ้นที่กำไร"
                  value={stats.winCount || 0}
                />
              </Col>
              <Col {...grids}>
                <Statistic
                  title="จำนวนหุ้นที่ขาดทุน"
                  value={stats.lossCount || 0}
                />
              </Col>
            </Row>
          </div>
          <div
            style={{
              marginTop: 10,
              width: "100%",
              backgroundColor: COLORS.CARD_BG,
            }}
          >
            <div
              style={{
                textAlign: "center",
                padding: 15,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Title
                level={4}
                style={{
                  margin: 0,
                }}
              >
                งบกำไร / ขาดทุน รายตัว
              </Title>
            </div>
            <Table
              columns={portfolioColumns}
              dataSource={currentPortfolios}
              pagination={false}
              scroll={{ x: true }}
              size="small"
              rowKey={"id"}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ByUser;
