import React from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Spin,
  Statistic,
  Table,
  Typography,
} from "antd";
import { User } from "./models";
import { getUser } from "./services/api";
import { LoadingOutlined } from "@ant-design/icons";
import { PageLogin, PageLoading, PageHome } from "./pages";

import "./App.css";

const { Title, Text } = Typography;

declare global {
  interface Number {
    format: (n: number, x?: number) => string;
  }
}
Number.prototype.format = function (n, x) {
  const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};

function App() {
  const [user, setUser] = React.useState<User>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  React.useEffect(() => {
    setLoading(true);
    getUser()
      .then((user) => setUser(user))
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });

    if (pathname == "/") {
      navigate("/home");
    }
  }, [pathname]);

  return loading ? (
    <PageLoading />
  ) : (
    <Routes>
      <Route path="login" element={<PageLogin />} />
      <Route
        path="home/*"
        element={
          <RequireAuth user={user}>
            <PageHome />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function RequireAuth({
  user,
  children,
}: {
  user?: User;
  children: JSX.Element;
}) {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default App;
