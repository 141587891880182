import React from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Typography,
  Menu,
} from "antd";
import {
  getLatestCustomerPortfolio,
  getLogout,
  listCustomer,
  postLogin,
} from "../services/api";
import { Customer, Portfolio } from "../models";
import { DefaultOptionType } from "antd/lib/select";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { portfolioColumns } from "../constants/columns";
import { calBaht, calPercent } from "../methods";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { TabsBySummary, TabsByUser, TabsAllPort } from "../tabs";
import ModalCustomers from "../modals/customers";
import COLORS from "../constants/color";

const { Title, Text } = Typography;

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [modalCustomersVisible, setModalCustomersVisible] =
    React.useState<boolean>(false);

  return (
    <>
      <ModalCustomers
        visible={modalCustomersVisible}
        onModalCustomersClose={() => setModalCustomersVisible(false)}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            backgroundColor: COLORS.MENU_BG,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Space
            style={{
              padding: 10,
              alignSelf: "flex-end",
            }}
          >
            <Button
              className="transparent-button"
              type="primary"
              shape="round"
              icon={<ContactsOutlined />}
              onClick={() => {
                setModalCustomersVisible(true);
              }}
            >
              รายชื่อลูกค้า
            </Button>
            <Button
              className="transparent-button"
              type="primary"
              shape="round"
              onClick={() => {
                getLogout();
              }}
            >
              ออกจากระบบ
            </Button>
          </Space>
          <Menu
            mode="horizontal"
            style={{ background: "transparent", borderBottom: "none" }}
            theme="dark"
            selectedKeys={[pathname.replace("/home", "")]}
            onSelect={({ key }) => {
              navigate("/home" + key);
            }}
          >
            <Menu.Item key="">ภาพรวม</Menu.Item>
            <Menu.Item key="/ports">พอร์ตทั้งหมด</Menu.Item>
            <Menu.Item key="/user">ตามรหัสลูกค้า</Menu.Item>
          </Menu>
        </div>
        <div
          style={{
            flex: 1,
            minHeight: 0,
            overflowX: "hidden",
            overflowY: "auto",
            // padding: 20,
            backgroundColor: COLORS.BG,
          }}
        >
          <Routes>
            <Route index element={<TabsBySummary />} />
            <Route path="ports" element={<TabsAllPort />} />
            <Route path="user" element={<TabsByUser />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default Home;
