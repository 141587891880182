import { Portfolio } from "../models";

export const calPercent = (p: Portfolio) => {
  if (p.symbol.market_price != null && p.unit_capital_price != null) {
    return (
      ((p.symbol.market_price - p.unit_capital_price) / p.unit_capital_price) *
      100
    );
  }
  return null;
};

export const calBaht = (p: Portfolio) => {
  if (
    p.symbol.market_price != null &&
    p.unit_capital_price != null &&
    p.quantity != null
  ) {
    return (p.symbol.market_price - p.unit_capital_price) * p.quantity;
  }
  return null;
};
