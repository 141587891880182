import React from "react";
import { Typography, Form, Input, Button, Card, Spin } from "antd";
import { User } from "../models";
import { LoadingOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

function Loading() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
    </div>
  );
}

export default Loading;
