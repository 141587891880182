import axios from "axios";
import config from "../config";
import { Customer, LoginResponse, Portfolio, User } from "../models";

export const listCustomer = async () => {
  const res = await axios.get<Customer[]>(`${config.API_URL}/api/customer`, {
    withCredentials: true,
  });
  return res.data;
};

export const getLatestCustomerPortfolio = async (customer: Customer) => {
  const res = await axios.get<Portfolio[]>(`${config.API_URL}/api/portfolio`, {
    params: {
      customer_id: customer.customer_id,
      mode: "latest",
    },
    withCredentials: true,
  });
  return res.data;
};

export const listPortfolio = async (mode?: "latest") => {
  const res = await axios.get<Portfolio[]>(`${config.API_URL}/api/portfolio`, {
    params: { mode },
    withCredentials: true,
  });
  return res.data;
};

export const postLogin = async (username: string, password: string) => {
  const res = await axios.post<LoginResponse>(
    `${config.API_URL}/api/login`,
    {
      username,
      password,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
  return res.data;
};

export const getLogout = async () => {
  const res = await axios.get(`${config.API_URL}/api/login`, {
    withCredentials: true,
  });
  window.location.reload();
  return res.data;
};

export const getUser = async () => {
  const res = await axios.get<User>(`${config.API_URL}/api/user`, {
    withCredentials: true,
  });
  return res.data;
};
