import React from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Typography,
  Input,
  Spin,
} from "antd";
import {
  getLatestCustomerPortfolio,
  getLogout,
  listCustomer,
  listPortfolio,
  postLogin,
} from "../services/api";
import { Customer, Portfolio } from "../models";
import { DefaultOptionType } from "antd/lib/select";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ContactsOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { allPortfolioColumns, portfolioColumns } from "../constants/columns";
import { calBaht, calPercent } from "../methods";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import COLORS from "../constants/color";

const { Title, Text } = Typography;
const { Search } = Input;

const grids = {
  xs: 24,
  sm: 24,
  md: 12,
  xl: 6,
};

const insideFormStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const LABEL_SPAN = 10;
const FORM_SPAN = 24 - LABEL_SPAN;

const ModeOptions = [
  { label: "ทั้งหมด", value: "ALL" },
  { label: "ที่กำไร", value: "PROFIT" },
  { label: "ที่ขาดทุน", value: "LOSS" },
] as const;
export type ModeType = typeof ModeOptions[number]["value"];

function AllPort() {
  const navigate = useNavigate();
  const [portfolios, setPortfolios] = React.useState<Portfolio[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [percentFrom, setPercentFrom] = React.useState<number | undefined>();
  const [percentTo, setPercentTo] = React.useState<number | undefined>();
  const [mode, setMode] = React.useState<ModeType>("ALL");
  const [selectedSymbols, setSelectedSymbols] = React.useState<string>();
  const [symbolOptions, setSymbolOptions] = React.useState<DefaultOptionType[]>(
    []
  );
  const [selectedAo, setSelectedAo] = React.useState<string>();
  const [aoOptions, setAoOptions] = React.useState<DefaultOptionType[]>([]);

  React.useEffect(() => {
    setLoading(true);
    listPortfolio("latest")
      .then((p) => {
        setPortfolios(p);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (portfolios) {
      const uniqueSymbolSet = new Set(portfolios.map((p) => p.symbol.symbol));
      setSymbolOptions(
        Array.from(uniqueSymbolSet).map((p) => ({
          label: p,
          value: p,
        }))
      );

      const uniqueAoSet = new Set(
        portfolios.map((p) => p.customer.marketer_id)
      );
      setAoOptions(
        Array.from(uniqueAoSet).map((a) => ({
          label: a,
          value: a,
        }))
      );
    }
  }, [portfolios]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          marginTop: 10,
          width: "100%",
          backgroundColor: COLORS.CARD_BG,
        }}
      >
        <div
          style={{
            textAlign: "center",
            padding: 15,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Title
            level={4}
            style={{
              margin: 0,
            }}
          >
            {`กำไร / ขาดทุนรายตัว`}
          </Title>
        </div>
        {loading ? (
          <div style={{ width: "100%", textAlign: "center", padding: 20 }}>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
            />
          </div>
        ) : (
          <>
            <div
              style={{
                marginTop: 10,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row gutter={[48, 16]} style={{ padding: 16 }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Radio.Group
                    options={ModeOptions as any}
                    onChange={(e) => setMode(e.target.value)}
                    value={mode}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ width: "100%" }}
                    size="large"
                  />
                </Col>
                <Col {...grids} style={insideFormStyle}>
                  <Text style={{ flex: LABEL_SPAN }}>กรองด้วย AO</Text>
                  <Select
                    size="large"
                    // mode="multiple"
                    allowClear
                    showSearch
                    placeholder="กรองด้วย AO"
                    value={selectedAo}
                    onChange={(value) => setSelectedAo(value)}
                    options={aoOptions}
                    style={{ flex: FORM_SPAN, marginLeft: 8 }}
                  />
                </Col>
                <Col {...grids} style={insideFormStyle}>
                  <Text style={{ flex: LABEL_SPAN }}>กรองด้วยสัญลักษณ์</Text>
                  <Select
                    size="large"
                    // mode="multiple"
                    allowClear
                    showSearch
                    placeholder="กรองด้วยสัญลักษณ์"
                    value={selectedSymbols}
                    onChange={(value) => setSelectedSymbols(value)}
                    options={symbolOptions}
                    style={{ flex: FORM_SPAN, marginLeft: 8 }}
                  />
                </Col>
                <Col {...grids} style={insideFormStyle}>
                  <Text style={{ flex: LABEL_SPAN }}>กรองเปอร์เซ็นมากกว่า</Text>
                  <InputNumber
                    size="large"
                    min={0}
                    value={percentFrom}
                    onChange={(value) => setPercentFrom(value || undefined)}
                    addonAfter="%"
                    style={{ flex: FORM_SPAN, marginLeft: 8 }}
                  />
                </Col>
                <Col {...grids} style={insideFormStyle}>
                  <Text style={{ flex: LABEL_SPAN }}>
                    กรองเปอร์เซ็นน้อยกว่า
                  </Text>
                  <InputNumber
                    size="large"
                    min={percentFrom || 0}
                    value={percentTo}
                    onChange={(value) => setPercentTo(value || undefined)}
                    addonAfter="%"
                    style={{ flex: FORM_SPAN, marginLeft: 8 }}
                  />
                </Col>
              </Row>
            </div>
            <Table
              columns={allPortfolioColumns}
              dataSource={portfolios
                .filter((p) => {
                  if (mode == "ALL") return true;
                  const isGain =
                    !p.symbol.market_price ||
                    !p.unit_capital_price ||
                    p.symbol.market_price >= p.unit_capital_price;
                  return (
                    (isGain && mode == "PROFIT") || (!isGain && mode == "LOSS")
                  );
                })
                .filter((p) => {
                  const percent = calPercent(p);
                  if (!percent) return true;
                  return (
                    (!percentFrom || Math.abs(percent) >= percentFrom) &&
                    (!percentTo || Math.abs(percent) <= percentTo)
                  );
                })
                .filter((p) => {
                  return !selectedSymbols || p.symbol.symbol == selectedSymbols;
                })
                .filter((p) => {
                  return !selectedAo || p.customer.marketer_id == selectedAo;
                })}
              pagination={false}
              scroll={{ x: true }}
              size="small"
              rowKey={"id"}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default AllPort;
